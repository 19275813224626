import React from "react"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { Image } from "react-bootstrap"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStoreState } from "easy-peasy"
import Amplify, { API } from "aws-amplify"
import Spinner from "../../components/common/Spinner"
import CenteredLayout from "../../components/layout/CenteredLayout"
import { connectorLogoURL } from "../../helpers/assets"
import awsconfig from "../../aws-exports"
import { useCallbackHandler } from "../../helpers/callback"
import HybridStorage from "../../config/hybridStorage"

Amplify.configure({
  ...awsconfig,
  storage: new HybridStorage({
    domain: process.env.NEPAL_DOMAIN,
    expires: 7,
    path: "/",
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
  }),
});

const Callback = () => {
  const location = useLocation()
  const { code, state } = parse(location.search)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const storeCredentials = async () => {
    return API.post("nepal", "/intercom/callback", {
      body: {
        code,
        organizationID: currentOrg.id,
      },
    });
  }

  const { connector } = useCallbackHandler(state, storeCredentials, "intercom")

  return (connector) ? (
    <CenteredLayout>
      <h6 className="text-uppercase text-muted mb-4">
        <FormattedMessage
          id="pages.intercom.callback.successHeader"
          defaultMessage="Huzah!"
          description="{connectorName} callback success header"
          values={{ connectorName: connector?.name }}
        />
      </h6>
      <h1 className="display-4 mb-3">
        <Image
          className="connector-card--image mb-3"
          src={(connector?.logo) ? connector?.logo : connectorLogoURL()}
          onError={e => {
            if (e.target.src !== connectorLogoURL())
              e.target.src = connectorLogoURL()
          }}
        />
        <br />
        <FormattedMessage
          id="pages.intercom.callback.intercomConnectedHeader"
          defaultMessage="{connectorName} connected"
          description="{connectorName} callback connected header"
          values={{ connectorName: connector?.name }}
        />{" "}
        <span role="img" aria-label="sparkles">
          ✨
        </span>
      </h1>
      <p className="text-muted mb-4">
        <FormattedMessage
          id="pages.intercom.callback.syncingParagraph"
          defaultMessage={`Let's sync your {connectorName} data…`}
          description="{connectorName} callback syncing your data paragraph"
          values={{ connectorName: connector?.name }}
        />
      </p>
      <Spinner />
    </CenteredLayout>
  )
  : null
}

export default Callback
